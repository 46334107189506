var $body = $('body');
var stickyOffset = $('header').height();
var myPlayer = $('.player');
$(document).ready(function () {

    //Table
    $("#equipmentTable .specification-table").clone(true).appendTo('#equipmentTable').addClass('clone');
    $("#technicalTable .specification-table").clone(true).appendTo('#technicalTable').addClass('clone');

    //Welcome Screen
    $('.welcome-screen-wrap').fadeIn('slow', function () {
        $('.welcome-screen-wrap').delay(1500).fadeOut();
    });

    //Interlinking Menu
    $('.header-main .navigation a[href*=#]').bind('click', function (e) {
        if (win_width() < 992) {
            $(this).parents().find('.menu--open').removeClass();
        }
        e.preventDefault(); // prevent hard jump, the default behavior

        var target = $(this).attr("href"); // Set the target as variable

        // perform animated scrolling by getting top-position of target-element and set it as scroll target
        $('html, body').stop().animate({scrollTop: $(target).offset().top - stickyOffset}, 600);
        return false;
    });

    //Mobile Menu
    $('.menu-trigger').on('click', function () {
        $('body').toggleClass('menu--open');
        nav_height();
    });

    // Go to top
    $(".goto-top").click(function () {
        $("html, body").animate({scrollTop: 0}, 1000);
        return false;
    });

    //Single Column Carousel    
    var singleSlider = $('.single-slider-init');
    if (singleSlider.length) {
        if (singleSlider.find('.single-slide').length > 1) {
            singleSlider.owlCarousel({
                items: 1,
                dotsEach: true,
                nav: true,
                dots: true,
                autoHeight: true,
                loop: true,
                autoplay: false,
                touchDrag: true,
                mouseDrag: false,
                navText: '',
                onInitialize: function () {
                    if (singleSlider.find('.slide').length === 1) {
                        this.settings.loop = false;
                        this.settings.nav = false;
                    }
                }
            });
        } else {
            singleSlider.show();
        }
    }

    //Two Carousel    
    var columnSlider = $('.column-slider-init');

    if (columnSlider.length) {
        if (columnSlider.find('.column-slide').length > 1) {
            columnSlider.owlCarousel({
                items: 2,
                dotsEach: true,
                nav: true,
                dots: true,
                autoHeight: true,
                loop: true,
                autoplay: false,
                touchDrag: true,
                mouseDrag: false,
                navText: '',
                onInitialize: function () {
                    if (columnSlider.find('.slide').length === 1) {
                        this.settings.loop = false;
                        this.settings.nav = false;
                    }
                },
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    }
                }
            });
        } else {
            columnSlider.show();
        }
    }


    //landing Video
    var options = {
        mobileFallbackImage: "../images/main-video-poster.jpg",
        playOnlyIfVisible: true,
        startAt: 0,
        loop: true,
        vol: 100,
        showYTLogo: false,
        showControls: false,
        showAnnotations: true,
        abundance: 0,
        cc_load_policy: false,
        useOnMobile: true,
        realfullscreen: false,
        gaTrack: true,
        onScreenPercentage: 50
    };
    myPlayer = $(".player").YTPlayer(options);
    //Video Mute/Unmute Button
    $(".unmute-btn").on('click', function () {
        $('.player').YTPToggleVolume();
        $(this).toggleClass('un--muted');
    });

    AnimatedLoad.Init();
    custom_dropdown();
});

$(window).resize(function () {
    nav_height();
});

var previousScroll = 0;
$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var menuScroll = $(window).scrollTop() + stickyOffset;

    // Assign active class to nav links while scolling
    $('.site-main section').each(function (i) {
        if ($(this).position().top <= menuScroll) {
            $('.navigation a.active').removeClass('active');
            $('.navigation a').eq(i).addClass('active');
        }
    });

    // Fixed Menu
    if (scroll >= 300) {
        $('body').addClass('fixed-header');
    } else {
        $('body').removeClass('fixed-header');
    }

    /* for go to top arrow */
    var currentScroll = $(this).scrollTop();
    if (currentScroll > previousScroll) {
        if (scroll >= stickyOffset + 100) {
            $('.goto-top').addClass('visible');
        }
    } else {
        $('.goto-top').removeClass('visible');
    }

});

$(document).on("touchstart click", function (e) {
    container = $('.custom-dropdown');
    contactInfo = $('.custom-dropdown');
    if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0 && !contactInfo.is(e.target) && contactInfo.has(e.target).length === 0)
    {
        $(".custom-dropdown .options").hide();
    }
});

function nav_height() {
    if (win_width() >= 992) {
        $('.navigation').css('height', 'auto');
    } else {
        $('.navigation').css('height', win_height() - header_height());
    }
}
function win_height() {
    return $(window).outerHeight();
}
function win_width() {
    return $(window).width();
}
function header_height() {
    return $('header.page').outerHeight();
}

//Custom Drop down
function custom_dropdown() {
    if (win_width() <= 767) {
        $(".custom-dropdown .selected a").click(function (e) {
            $(this).parent().siblings().toggle();
            e.preventDefault();
        });
        $(".custom-dropdown .options li a").click(function (e) {
            var text = $(this).html();
            $(".custom-dropdown .selected a span").html(text);
            $(".custom-dropdown .options").hide();
            e.preventDefault();
        });
    }
}

/**************************************************************************************************************************************/
/* Animated Load */
/**************************************************************************************************************************************/
var AnimatedLoad = new function () {

    // Konstanten
    var cSelector = '.AnimatesOnLoad';
    var cSubItemsSelector = 'div.divImage, div.divTextImage';

    /*
     Init
     */
    this.Init = function () {

        // Elemente
        var oContent = $(cSelector);
        if (oContent.length <= 0)
            return;

        // InView
        oContent.on('inview', InViewAnimate);

    }
    var InViewAnimate = function (oEvent, bIsInViev) {
        if (bIsInViev) {

            // Item
            var oItem = $(this);

            // Animate	
            oItem.css('visibility', 'visible').css('opacity', 0).animate({
                top: 0,
                opacity: 1
            });

            // Unbind
            oItem.off('inview');

            // SubItems
            var oSubItems = oItem.find(cSubItemsSelector);
            if (oSubItems.length <= 0)
                return;

            // Show
            oSubItems.delay(500).css('visibility', 'visible').css('opacity', 0).animate({
                opacity: 1
            });

        }
    }

}